<template>
  <div class="login-container"> 
    <img class="login-logo" src="../assets/refika.svg" />  
    <p class="title">STÜDYO/ŞUBE GİRİŞİ</p>
    <p class="subtitle">Branch Login</p>
    <form @submit.prevent="login"> 
      <div class="input-areas">
        <input id="email" v-model="form.email" type="email" placeholder="E-mail" required />
        <input id="password" v-model="form.password" type="password" placeholder="Password" required />
      </div>
      <div v-if="message" :class="messageClass">{{ message }}</div> 
      <img 
        src="../assets/icon/login-icon.svg" 
        alt="Login Icon" 
        class="submit-img" 
        @click="login"
      />
    </form>  
  </div>
</template>

<script>
import axios from 'axios'; 

export default { 
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      message: '',
      messageClass: 'error',
    };
  },
  methods: { 
    async login() {
      try {
        const response = await axios.post('/noah/login', this.form);
        console.log('Login successful:', response.data);
        localStorage.setItem('token', response.data.token);
        this.message = 'Login successful';
        this.messageClass = 'success';
      } catch (error) {
        console.error('Login error:', error.response ? error.response.data : error.message);
        this.message = 'Login failed. Please check your credentials.';
        this.messageClass = 'error';
      }
    },
  },
};
</script>


<style scoped>
.login-container {
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.login-logo {
  max-width: 150px;
  height: auto;
  margin-bottom: 10px;
} 

.input-areas {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  min-width: 320px;
}

.submit-img {
  cursor: pointer;
  max-width: 4.80em;
  opacity: 0.5;
  margin: 0 auto;
  display: block;
}

.submit-img:hover {
  opacity: 1;
}

.error { 
  color: #d00000;
  margin-bottom: 1em;
}

.success { 
  color: #1ed760;
  margin-bottom: 1em;
} 

.title{ 
  margin: 10px;
  margin-bottom: 10;
  font-size: 2em;
  font-weight: 300;
  color: #474747;
  text-align: left;
}
.subtitle{ 
  margin: 0 0 10px 0;
  font-size: 1em;
  font-weight: 300;
  color: #474747;
  text-align: left;
}
</style>
